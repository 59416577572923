import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Modal, message } from "antd";
import { AxiosError } from "axios";
import useColors from "../Hooks/useColors";
import useConfig from "../Hooks/useConfig";
import UserService from "../Services/UserService";
import ReactPaginate from "react-paginate";
import PageService, { page } from "../Services/PageService";
const key = "Loading";
const Page: React.FC = () => {
  const [, Colors] = useColors();
  const navigate = useNavigate();
  const { name } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [content, setcontent] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const config = useConfig();
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    navigate("/Login", { replace: true });
  };
  const ErrorMessage = () => {
    messageApi.open({
      key,
      type: "error",
      content: "Error!",
      duration: 2,
    });
  };
  const successMessage = () => {
    messageApi.open({
      key,
      type: "success",
      content: "تم!",
      duration: 2,
    });
  };
  const endCall = () => {
    setLoading(false);
    successMessage();
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        messageApi.open({
          key,
          type: "loading",
          content: "جار التحميل...",
          duration: 0,
        });
        const result = await PageService.getByName(name || "");
        setcontent(result);
        successMessage();
      } catch (error) {
        // Handle error
        ErrorMessage();

        const err = error as AxiosError<{ error: string }>;
        if (err.response?.status === 401) {
          logout();
        }
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="container p-3 pt-4">
        {contextHolder}
        <div className="h2">{name}</div>
        {content}
      </div>
    </>
  );
};

export default Page;
