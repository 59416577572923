import React, { useContext, useEffect } from "react";

const useConfig = () => {
  const token = localStorage.getItem("token");
  return {
    timeout: 10000,
    headers: { Authorization: `Bearer ${token}` },
  };
};

export default useConfig;
