import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
export interface page {
  name: string;
  content: string;
}
class PageService {
  static async create(name: string, content: string, config: AxiosRequestConfig): Promise<number> {
    try {
      const response = await axios.post<number>(`${process.env.REACT_APP_MAIN_URL || ""}page`, { name, content }, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw { message: error.response?.data ?? error.message, status: error.response?.status };
    }
  }
  static async delete(name: string, config: AxiosRequestConfig): Promise<void> {
    try {
      await axios.delete<void>(`${process.env.REACT_APP_MAIN_URL || ""}page/${name}`, config);
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async update(name: string, content: string, config: AxiosRequestConfig): Promise<void> {
    try {
      await axios.put<void>(`${process.env.REACT_APP_MAIN_URL || ""}page`, { name, content }, config);
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async get(config: AxiosRequestConfig): Promise<page[]> {
    try {
      let url = `${process.env.REACT_APP_MAIN_URL || ""}page`;
      const response = await axios.get<page[]>(url, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async getByName(name: string): Promise<string> {
    try {
      let url = `${process.env.REACT_APP_IMAGE_URL || ""}public/page/${name}`;
      const response = await axios.get<string>(url);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
}

export default PageService;
