import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Category, User } from "../type";
const config = (token: string) => ({
  timeout: 10000,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
});
class CategoryService {
  static async create(name: string, photo: File, token: string): Promise<number> {
    try {
      const formData = new FormData();
      formData.append("photo", photo);
      formData.append("name", name);
      const response = await axios.post<number>(`${process.env.REACT_APP_MAIN_URL || ""}category`, formData, config(token));
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw { message: error.response?.data ?? error.message, status: error.response?.status };
    }
  }
  static async delete(id: string, config: AxiosRequestConfig): Promise<void> {
    try {
      await axios.delete<void>(`${process.env.REACT_APP_MAIN_URL || ""}category/${id}`, config);
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async update(id: string, name: string, token: string, photo?: File): Promise<void> {
    try {
      const formData = new FormData();
      if (photo) formData.append("photo", photo);
      formData.append("name", name);
      await axios.put<void>(`${process.env.REACT_APP_MAIN_URL || ""}category/${id}`, formData, config(token));
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }

  static async get(config: AxiosRequestConfig): Promise<Category[]> {
    try {
      let url = `${process.env.REACT_APP_MAIN_URL || ""}category`;
      const response = await axios.get<Category[]>(url, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async search(keyword: string, config: AxiosRequestConfig): Promise<Category[]> {
    try {
      let url = `${process.env.REACT_APP_MAIN_URL || ""}category/search/${keyword}`;
      const response = await axios.get<Category[]>(url, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
}

export default CategoryService;
