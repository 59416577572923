import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Product } from "../type";
const config = (token: string) => ({
  timeout: 10000,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
});
class ProductService {
  static async create(
    name: string,
    user_id: string,
    area_id: string,
    Category_id: string,
    photo: File,
    description: string,
    price: number,
    inventory_count: number,
    token: string
  ): Promise<number> {
    try {
      const formData = new FormData();
      formData.append("photo", photo);
      formData.append("name", name);
      formData.append("area_id", area_id);
      formData.append("Category_id", Category_id);
      formData.append("user_id", user_id);
      formData.append("description", description);
      formData.append("price", `${price}`);
      formData.append("inventory_count", `${inventory_count}`);
      const response = await axios.post<number>(`${process.env.REACT_APP_MAIN_URL || ""}product`, formData, config(token));
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw { message: error.response?.data ?? error.message, status: error.response?.status };
    }
  }
  static async delete(id: string, config: AxiosRequestConfig): Promise<void> {
    try {
      await axios.delete<void>(`${process.env.REACT_APP_MAIN_URL || ""}product/${id}`, config);
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async update(
    id: string,
    name: string,
    area_id: string,
    Category_id: string,
    description: string,
    price: number,
    inventory_count: number,
    token: string,
    photo?: File
  ): Promise<void> {
    try {
      const formData = new FormData();
      if (photo) formData.append("photo", photo);
      formData.append("name", name);
      formData.append("area_id", area_id);
      formData.append("Category_id", Category_id);
      formData.append("description", description);
      formData.append("price", `${price}`);
      formData.append("inventory_count", `${inventory_count}`);
      await axios.put<void>(`${process.env.REACT_APP_MAIN_URL || ""}product/${id}`, formData, config(token));
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }

  static async get(id: string, config: AxiosRequestConfig): Promise<{ Products: Product[]; pages: number }> {
    try {
      let url = `${process.env.REACT_APP_MAIN_URL || ""}product/${id}`;
      const response = await axios.get<{ Products: Product[]; pages: number }>(url, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async search(id: string, keyword: string, config: AxiosRequestConfig): Promise<Product[]> {
    try {
      let url = `${process.env.REACT_APP_MAIN_URL || ""}product/${id}/search/${keyword}`;
      const response = await axios.get<Product[]>(url, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
}

export default ProductService;
