import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Area, User } from "../type";
const config = (token: string) => ({
  timeout: 10000,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
});
class AreaService {
  static async create(name: string, config: AxiosRequestConfig): Promise<number> {
    try {
      const response = await axios.post<number>(`${process.env.REACT_APP_MAIN_URL || ""}area`, { name }, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw { message: error.response?.data ?? error.message, status: error.response?.status };
    }
  }
  static async delete(id: string, config: AxiosRequestConfig): Promise<void> {
    try {
      await axios.delete<void>(`${process.env.REACT_APP_MAIN_URL || ""}area/${id}`, config);
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async update(id: string, name: string, config: AxiosRequestConfig): Promise<void> {
    try {
      await axios.put<void>(`${process.env.REACT_APP_MAIN_URL || ""}area/${id}`, { name }, config);
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }

  static async get(config: AxiosRequestConfig): Promise<Area[]> {
    try {
      let url = `${process.env.REACT_APP_MAIN_URL || ""}area`;
      const response = await axios.get<Area[]>(url, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async search(keyword: string, config: AxiosRequestConfig): Promise<Area[]> {
    try {
      let url = `${process.env.REACT_APP_MAIN_URL || ""}area/search/${keyword}`;
      const response = await axios.get<Area[]>(url, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
}

export default AreaService;
