import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
export interface settings {
  name: string;
  value: string;
}
class SettingsService {
  static async Store(settings: settings[], config: AxiosRequestConfig): Promise<void> {
    try {
      await axios.post<void>(`${process.env.REACT_APP_MAIN_URL || ""}settings`, { settings }, config);
    } catch (error) {
      // @ts-ignore
      throw { message: error.response?.data ?? error.message, status: error.response?.status };
    }
  }
  static async get(config: AxiosRequestConfig): Promise<{ coin: string; gamefee: string }> {
    try {
      const response = await axios.get<{ coin: string; gamefee: string }>(`${process.env.REACT_APP_MAIN_URL || ""}settings`, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
}

export default SettingsService;
