import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, message } from "antd";
import { AxiosError } from "axios";
import { CheckOutlined, DeleteOutlined, EditOutlined, MoneyCollectOutlined } from "@ant-design/icons";
import useColors from "../Hooks/useColors";
import useConfig from "../Hooks/useConfig";
import UserService from "../Services/UserService";
import ReactPaginate from "react-paginate";
import { User } from "../type";
const key = "Loading";
const Users: React.FC = () => {
  const [, Colors] = useColors();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [id, setid] = useState<string>("");
  const [keyword, setkeyword] = useState<string>("");
  const [first_name, setfirst_name] = useState<string>("");
  const [last_name, setlast_name] = useState<string>("");
  const [email, setemail] = useState<string>("");
  const [phone, setphone] = useState<string>("");
  const [password, setpassword] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [users, setusers] = useState<User[]>([]);
  const [page, setpage] = useState<number>(1);
  const [totalpages, settotalpages] = useState<number>(0);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [refresh, setrefresh] = useState<boolean>(false);
  const config = useConfig();
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    navigate("/Login", { replace: true });
  };
  const reset = () => {
    setfirst_name("");
    setlast_name("");
    setphone("");
    setpassword("");
  };
  const ErrorMessage = () => {
    messageApi.open({
      key,
      type: "error",
      content: "Error!",
      duration: 2,
    });
  };
  const successMessage = () => {
    messageApi.open({
      key,
      type: "success",
      content: "تم!",
      duration: 2,
    });
  };
  const endCall = () => {
    setrefresh(!refresh);
    setLoading(false);
    reset();
    setOpen(false);
    successMessage();
  };
  const save = async () => {
    try {
      setLoading(true);
      await UserService.create(first_name, last_name, email, phone, password, config);
      endCall();
    } catch (error) {
      // Handle error
      const err = error as { message: string; status: number };
      if (err.status === 422) {
        messageApi.open({
          key,
          type: "error",
          content: err.message,
          duration: 2,
        });
      } else ErrorMessage();
      setLoading(false);
    }
  };
  const updateType = async (id: string, isAdmin: boolean) => {
    try {
      setLoading(true);
      await UserService.updateType(id, isAdmin, config);
      endCall();
    } catch (error) {
      // Handle error
      ErrorMessage();
      setLoading(false);
    }
  };
  const update = async () => {
    try {
      setLoading(true);
      await UserService.update(id, first_name, last_name, email, phone, password, config);
      endCall();
    } catch (error) {
      // Handle error
      ErrorMessage();
      setLoading(false);
    }
  };
  const dprofile = async () => {
    try {
      setLoading(true);
      await UserService.delete(id, config);
      endCall();
    } catch (error) {
      // Handle error
      ErrorMessage();
      setLoading(false);
    }
  };
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    isSave ? save() : update();
  };

  const handleCancel = () => {
    setOpen(false);
    reset();
  };
  const renderProfiles = users.map((Element, index) => {
    return (
      <div
        key={index}
        className="d-flex mb-2 p-2"
        style={{
          borderBottomWidth: 1,
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderColor: Colors.dark,
          borderStyle: "dashed",
        }}
      >
        <div style={{ flex: 1 }}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-end">
              <div className="h4">
                {Element.first_name} {Element.last_name}
              </div>
            </div>
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  showModal();
                  setpassword(Element.password);
                  setphone(Element.phone);
                  setfirst_name(Element.first_name);
                  setlast_name(Element.last_name);
                  setemail(Element.email);
                  setIsSave(false);
                  setid(Element.id);
                }}
              >
                <EditOutlined style={{ fontSize: 30, color: Colors.primary }} />
              </div>
            </div>
          </div>
          <div className="d-flex align-items-end">
            <div className="h6">{Element.email}</div>
          </div>
          <div className="d-flex align-items-end">
            <div className="h6">{Element.phone}</div>
          </div>
          <div
            className={`btn ${Element.type === "admin" ? "btn-success" : "btn-danger"}`}
            onClick={() => {
              updateType(Element.id, Element.type === "user");
            }}
          >
            <div className="h6">{Element.type}</div>
          </div>
          <a href={`/Products/${Element.id}`} className={`btn btn-success m-2`}>
            <div className="h6">المنتجات</div>
          </a>
        </div>
      </div>
    );
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        messageApi.open({
          key,
          type: "loading",
          content: "جار التحميل...",
          duration: 0,
        });
        const result = await UserService.get(config, page);
        settotalpages(result.pages);
        setusers(result.users);
        successMessage();
      } catch (error) {
        // Handle error
        ErrorMessage();

        const err = error as AxiosError<{ error: string }>;
        if (err.response?.status === 401) {
          logout();
        }
      }
    };
    fetchData();
  }, [refresh, page]);
  const search = async () => {
    try {
      messageApi.open({
        key,
        type: "loading",
        content: "جار التحميل...",
        duration: 0,
      });
      const result = await UserService.search(keyword, config);
      setusers(result);
      successMessage();
    } catch (error) {
      // Handle error
      ErrorMessage();

      const err = error as AxiosError<{ error: string }>;
      if (err.response?.status === 401) {
        logout();
      }
    }
  };
  const handlePageClick = (selectedItem: { selected: number }) => {
    setpage(selectedItem.selected + 1);
  };
  const getFooter = () => {
    let data = [
      <Button key="back" onClick={handleCancel}>
        إلغاء
      </Button>,
      <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
        حفظ
      </Button>,
    ];
    !isSave &&
      data.push(
        <Button
          key="delete"
          type="default"
          onClick={() => {
            dprofile();
          }}
          danger
          icon={<DeleteOutlined />}
        />
      );
    return data;
  };
  return (
    <>
      <div className="container p-3 pt-4">
        {contextHolder}
        <div className="d-flex justify-content-between">
          <div
            className="p-2 px-4 d-flex mb-3 mx-auto"
            style={{
              backgroundColor: Colors.primary,
              borderRadius: 20,
              cursor: "pointer",
            }}
            onClick={() => {
              showModal();
              setIsSave(true);
            }}
          >
            <CheckOutlined style={{ fontSize: 24, color: Colors.light }} />
            <div
              className="mx-auto "
              style={{
                color: Colors.light,
                fontSize: 16,
              }}
            >
              جديد
            </div>
          </div>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            className="form-control"
            value={keyword}
            onChange={(e) => {
              setkeyword(e.target.value);
            }}
          />
          <button onClick={search} className="btn btn-primary">
            ابحث
          </button>
          <button
            onClick={() => {
              setrefresh(!refresh);
            }}
            className="btn btn-danger m-3"
          >
            الغاء نتائج البحث
          </button>
        </div>
        {renderProfiles}
        <Modal open={open} width={1000} title="المستخدمين" onOk={handleOk} onCancel={handleCancel} footer={getFooter}>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              value={first_name}
              onChange={(e) => {
                setfirst_name(e.target.value);
              }}
            />
            <label htmlFor="floatingInput">اسم الاول</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              value={last_name}
              onChange={(e) => {
                setlast_name(e.target.value);
              }}
            />
            <label htmlFor="floatingInput">اسم الأخير</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="number"
              className="form-control"
              value={phone}
              onChange={(e) => {
                setphone(e.target.value);
              }}
            />
            <label htmlFor="floatingInput">رقم الهاتف</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => {
                setemail(e.target.value);
              }}
            />
            <label htmlFor="floatingInput">الايميل</label>
          </div>
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              value={password}
              onChange={(e) => {
                setpassword(e.target.value);
              }}
            />
            <label htmlFor="floatingInput">كلمة السر</label>
          </div>
        </Modal>

        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={totalpages}
          previousLabel="< previous"
          renderOnZeroPageCount={null}
          className="pagination justify-content-center my3"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          activeClassName="active"
          disabledClassName="disabled"
          nextClassName="page-item"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
        />
      </div>
    </>
  );
};

export default Users;
