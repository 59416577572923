import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Card, Col, Modal, Row, message } from "antd";
import { AxiosError } from "axios";
import { CheckOutlined, DeleteOutlined, EditOutlined, MoneyCollectOutlined } from "@ant-design/icons";
import useColors from "../Hooks/useColors";
import useConfig from "../Hooks/useConfig";
import UserService, { nu } from "../Services/UserService";
import ReactPaginate from "react-paginate";
import { User } from "../type";
const key = "Loading";
const Home: React.FC = () => {
  const [, Colors] = useColors();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [result, setresult] = useState<{ users: nu; total_sales: nu; add_to_cart: nu; visits: nu }>();
  const config = useConfig();
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    navigate("/Login", { replace: true });
  };
  const ErrorMessage = () => {
    messageApi.open({
      key,
      type: "error",
      content: "Error!",
      duration: 2,
    });
  };
  const successMessage = () => {
    messageApi.open({
      key,
      type: "success",
      content: "تم!",
      duration: 2,
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        messageApi.open({
          key,
          type: "loading",
          content: "جار التحميل...",
          duration: 0,
        });
        const result = await UserService.home(config);
        setresult(result);
        successMessage();
      } catch (error) {
        // Handle error
        ErrorMessage();

        const err = error as AxiosError<{ error: string }>;
        if (err.response?.status === 401) {
          logout();
        }
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="container p-3 pt-4">
        {contextHolder}
        <div className="justify-content-center d-flex flex-row">
          <Row gutter={24}>
            <Col span={6}>
              <Card title="المستخدمين اليوم" bordered={false}>
                مستخدم جديد{result?.users.today}
              </Card>
              <Card title="المستخدمين هذا الاسبوع" bordered={false}>
                مستخدم جديد{result?.users.week}
              </Card>
              <Card title="المستخدمين هذا الشهر" bordered={false}>
                مستخدم جديد{result?.users.month}
              </Card>
              <Card title="المستخدمين كل الاوقات" bordered={false}>
                مستخدم جديد{result?.users.all}
              </Card>
            </Col>
            <Col span={6}>
              <Card title="اضافة الي السلة اليوم" bordered={false}>
                اضافة الي السلة جديدة{result?.add_to_cart.today}
              </Card>
              <Card title="اضافة الي السلة هذا الاسبوع" bordered={false}>
                اضافة الي السلة جديدة{result?.add_to_cart.week}
              </Card>
              <Card title="اضافة الي السلة هذا الشهر" bordered={false}>
                اضافة الي السلة جديدة{result?.add_to_cart.month}
              </Card>
              <Card title="اضافة الي السلة كل الاوقات" bordered={false}>
                اضافة الي السلة جديدة{result?.add_to_cart.all}
              </Card>
            </Col>
            <Col span={6}>
              <Card title="عملية بيع اليوم" bordered={false}>
                عملية بيع جديدة {result?.total_sales.today}
              </Card>
              <Card title="عملية بيع هذا الاسبوع" bordered={false}>
                عملية بيع جديدة{result?.total_sales.week}
              </Card>
              <Card title="عملية بيع هذا الشهر" bordered={false}>
                عملية بيع جديدة{result?.total_sales.month}
              </Card>
              <Card title="عملية بيع كل الاوقات" bordered={false}>
                عملية بيع جديدة{result?.total_sales.all}
              </Card>
            </Col>
            <Col span={6}>
              <Card title="الزيارات للمنتجات اليوم" bordered={false}>
                زيارة جديدة {result?.visits.today}
              </Card>
              <Card title="الزيارات للمنتجات هذا الاسبوع" bordered={false}>
                زيارة جديدة{result?.visits.week}
              </Card>
              <Card title="الزيارات للمنتجات هذا الشهر" bordered={false}>
                زيارة جديدة{result?.visits.month}
              </Card>
              <Card title="الزيارات للمنتجات كل الاوقات" bordered={false}>
                زيارة جديدة {result?.visits.all}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Home;
