import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { User } from "../type";
export interface nu {
  today: number;
  week: number;
  month: number;
  all: number;
}
class UserService {
  static async create(
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    password: string,
    config: AxiosRequestConfig
  ): Promise<number> {
    try {
      const response = await axios.post<number>(
        `${process.env.REACT_APP_MAIN_URL || ""}user`,
        { first_name, last_name, email, phone, password },
        config
      );
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw { message: error.response?.data ?? error.message, status: error.response?.status };
    }
  }
  static async delete(id: string, config: AxiosRequestConfig): Promise<void> {
    try {
      await axios.delete<void>(`${process.env.REACT_APP_MAIN_URL || ""}user/${id}`, config);
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async updateType(id: string, isAdmin: boolean, config: AxiosRequestConfig): Promise<void> {
    try {
      await axios.put<void>(`${process.env.REACT_APP_MAIN_URL || ""}user/updateType/${id}`, { isAdmin }, config);
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async update(
    id: string,
    first_name: string,
    last_name: string,
    email: string,
    phone: string,
    password: string,
    config: AxiosRequestConfig
  ): Promise<void> {
    try {
      await axios.put<void>(`${process.env.REACT_APP_MAIN_URL || ""}user/${id}`, { first_name, last_name, email, phone, password }, config);
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }

  static async login(email: string, password: string): Promise<{ token: string; user_id: number }> {
    const response: AxiosResponse<{ token: string; user_id: number }> = await axios.post(`${process.env.REACT_APP_IMAGE_URL || ""}loginAdmin`, {
      email,
      password,
    });
    return response.data;
  }

  static async logout(config: any): Promise<void> {
    await axios.get(`${process.env.REACT_APP_MAIN_URL || ""}user/logout`, config);
  }
  static async get(config: AxiosRequestConfig, page?: number): Promise<{ users: User[]; pages: number }> {
    try {
      let url = `${process.env.REACT_APP_MAIN_URL || ""}user`;
      if (page) {
        url += "?page=" + page;
      }
      const response = await axios.get<{ users: User[]; pages: number }>(url, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async home(config: AxiosRequestConfig): Promise<{ users: nu; total_sales: nu; add_to_cart: nu; visits: nu }> {
    try {
      let url = `${process.env.REACT_APP_MAIN_URL || ""}user/home`;
      const response = await axios.get<{ users: nu; total_sales: nu; add_to_cart: nu; visits: nu }>(url, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
  static async search(keyword: string, config: AxiosRequestConfig): Promise<User[]> {
    try {
      let url = `${process.env.REACT_APP_MAIN_URL || ""}user/search/${keyword}`;
      const response = await axios.get<User[]>(url, config);
      return response.data;
    } catch (error) {
      // @ts-ignore
      throw error;
    }
  }
}

export default UserService;
