import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
interface params {
  p?: string;
  photoSelected: (photo: File) => void;
  photo?: File;
}
const Upload: React.FC<params> = ({ p, photoSelected, photo }) => {
  const [dropArea, setdropArea] = useState<Element>();
  const [pickedphoto, setpickedphoto] = useState<string>();
  useEffect(() => {
    p && setoldPhoto(p);
  }, [p]);
  const navigate = useNavigate();
  const [oldPhoto, setoldPhoto] = useState<string>(p || "");
  const [dragText, setdragText] = useState<Element>();
  const [dropAreaInput, setdropAreaInput] = useState<HTMLInputElement>();
  useEffect(() => {
    const dd = document.querySelector(".drag-area");

    if (dd) {
      setdropArea(dd);
      setdragText(dd.querySelector("header") || undefined);
    }
    // @ts-ignore
    setdropAreaInput(document.querySelector(".drag-area-input") || undefined);
  }, []);
  const uploadBtnclick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    dropAreaInput && dropAreaInput.click();
  };
  const dropAreaInputChanged = () => {
    if (dropAreaInput && dropAreaInput.files) {
      photoSelected(dropAreaInput.files[0]);
      showfile(dropAreaInput.files[0]);
    }
  };
  const showfile = (file: File) => {
    let fileType = file.type;
    let validExetentions = ["image/jpeg", "image/jpg", "image/png"];
    if (validExetentions.includes(fileType)) {
      let fileReader = new FileReader();
      fileReader.onload = () => {
        let fileUrl = fileReader.result;
        if (typeof fileUrl === "string") setpickedphoto(fileUrl);
      };
      fileReader.readAsDataURL(file);
    } else {
      alert("this not an image!");
      dropArea && dropArea.classList.remove("active");
    }
  };
  const dropAreaDragOver: React.DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    dropArea && dropArea.classList.add("active");
    dragText && (dragText.textContent = "relase to upload");
  };
  const dropAreaOnDragLeave: React.DragEventHandler<HTMLDivElement> = (e) => {
    dropArea && dropArea.classList.remove("active");
    dragText && (dragText.textContent = "drag & drop to upload a Photo");
  };
  const dropAreaDrop: React.DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    dropArea && dropArea.classList.remove("active");
    dragText && (dragText.textContent = "drag & drop to upload a Photo");
    // getting user selected files
    photoSelected(e.dataTransfer.files[0]);

    dropAreaInput && (dropAreaInput.files = e.dataTransfer.files);
    // console.log(dropAreaInput.files)
    showfile(e.dataTransfer.files[0]);
  };

  return (
    <div className="d-flex flex-column w-100 align-items-center justify-content-center mt-4">
      {oldPhoto !== "" && <img src={process.env.REACT_APP_IMAGE_URL + "/" + oldPhoto} alt="" className="img-fluid" style={{ maxHeight: 100 }} />}
      <div className="form-floating mb-3">
        <div className="drag-area" onDragOver={dropAreaDragOver} onDragLeave={dropAreaOnDragLeave} onDrop={dropAreaDrop}>
          {photo ? (
            <img className="img-fluid" style={{ maxWidth: 300 }} src={pickedphoto} />
          ) : (
            <div className="icon">
              <i className="fas fa-cloud-upload-alt"></i>
            </div>
          )}
          <header>drag & drop to upload a photo</header>
          <span>OR</span>
          <button onClick={uploadBtnclick}>browser a photo</button>
        </div>
        <input onChange={dropAreaInputChanged} className="drag-area-input" type="file" name="photo" hidden />
      </div>
    </div>
  );
};
export default Upload;
