import React from "react";
import logo from "./logo.svg";
import "./App.css";
import "./upload.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import Home from "./Pages/Home";
import NoPage from "./Pages/NoPage";
import Login from "./Pages/Login";
import Users from "./Pages/Users";
import Settings from "./Pages/Settings";
import Pages from "./Pages/Pages";
import Page from "./Pages/Page";
import Category from "./Pages/Category";
import Products from "./Pages/Products";
import Area from "./Pages/Area";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/Login" element={<Login />} />
        <Route path="/page/:name" element={<Page />} />
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/Users" element={<Users />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/Pages" element={<Pages />} />
          <Route path="/Category" element={<Category />} />
          <Route path="/Area" element={<Area />} />
          <Route path="/Products/:userid" element={<Products />} />
        </Route>
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
